// const BASE_URL = process.env.REACT_APP_BASE_URL;
const hostname = window.location.hostname;

const BASE_URL = ["finance-dev.prom.co.th", "localhost"].includes(hostname)
  ? "https://dev-single-payment-api.promrub.com"
  : hostname === "finance.prom.co.th"
  ? "https://single-payment-api.promrub.com"
  : "";

const API = {
  URL_AUTHORIZATION: `${BASE_URL}/v1/api/Authorization/org`,
  URL_ORGANIZATION: `${BASE_URL}/v1/api/Organization/org`,
  URL_CUSTOMER: `${BASE_URL}/v1/api/Customer/org`,

  URL_TAX_REPORT: `${BASE_URL}/v1/api/TaxReport/org`,
  URL_RECEIVE_PAYMENT: `${BASE_URL}/v1/api/ReceivePayment/org`,
  URL_MASTER: `${BASE_URL}/v1/api/Master/org`,
};

export default API;
